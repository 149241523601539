import type { I18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
	Alert,
	Box,
	Button,
	Card,
	Image,
	SegmentedControl,
	Skeleton,
	TextInput,
	Title,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useFetcher, useSearchParams } from "@remix-run/react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { zodResolver } from "mantine-form-zod-resolver";
import { useEffect, useState } from "react";
import { z } from "zod";
import type { Patient } from "~/types";
import { useAppState } from "~/utils/states";
import {
	apiFetcher,
	dateParser,
	handleDateValidation,
} from "~/utils/utils.client";
import Logo from "/logo.webp";
/*eslint-disable import/no-unresolved*/
import NewPatientModal from "./new-patient-modal";

const schema = (i18n: I18n) =>
	z.object({
		dob: z.date().nullable(),
		identityType: z.string(),
		identityNumber: z.string().min(2, t(i18n)`Wajib diisi`),
	});

export default function SignInBox() {
	const { i18n } = useLingui();
	const [identityType, setIdentityType] = useState("nik");
	const [opened, { open, close }] = useDisclosure(false);
	const fetcher = useFetcher();
	const { faskes, banners } = useAppState();
	const [logoUrl, setLogoUrl] = useState<string | null>(null);
	const [params] = useSearchParams();

	useEffect(() => {
		if (banners !== null && faskes !== null) {
			const url = banners.find(
				(banner) => banner.template_nama_variabel === "login_logo_file",
			);
			setLogoUrl(
				url
					? `/static-api/pelayanan1/uploads/banner_booking/${url.template_file}`
					: Logo,
			);
		}
	}, [banners, faskes]);

	const form = useForm({
		mode: "controlled",
		initialValues: {
			identityNumber: "",
			identityType: identityType,
			dob: new Date("2000-01-01"),
		},
		validate: zodResolver(schema(i18n)),
	});

	const { mutate: submitHandler, isPending } = useMutation({
		mutationKey: ["patient-check"],
		mutationFn: async () => {
			const dob = dayjs(form.values.dob).format("YYYY-MM-DD");
			if (!handleDateValidation(dob)) return;

			const results = await apiFetcher({
				path: "/v1/booking-online/patient",
				opts: {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						...form.values,
						dob: dob,
						identityType: identityType,
					}),
				},
			});

			if (results === null) {
				notifications.show({
					message: "Pasien tidak ditemukan",
					color: "red",
					withCloseButton: true,
				});
			}

			return results;
		},
		onSuccess: (data) => {
			const patient: Patient = {
				address: data.pasien_alamat,
				dob: data.pasien_tanggallahir,
				id: data.pasien_id,
				name: data.pasien_nama,
				email: data.pasien_email,
				nik: data.pasien_nik,
				norm: data.pasien_norm,
				image: data.pasien_foto,
				phone: data.pasien_nohp,
				sex: data.pasien_kelamin,
				verified: true,
			};

			// set cookie
			fetcher.submit(
				{
					patient: JSON.stringify(patient),
					next: params.get("next") ?? "/app",
				},
				{
					method: "post",
				},
			);
		},
	});
	const [isImageLoaded, setIsImageLoaded] = useState(false);

	return (
		<div className="flex flex-col items-center w-full md:w-2/3 lg:w-1/2">
			<Box pos="relative" w={100} h={100}>
				{!isImageLoaded && (
					<Skeleton height={100} circle pos="absolute" top={0} left={0} />
				)}
				<Image
					src={logoUrl}
					alt="logo"
					width={100}
					height={100}
					fit="cover"
					style={{
						opacity: isImageLoaded ? 1 : 0,
						transition: "opacity 0.3s",
					}}
					onLoad={() => setIsImageLoaded(true)}
					onError={(e) => {
						e.currentTarget.src = Logo;
					}}
				/>
			</Box>

			<div className="flex flex-col items-center">
				<Title order={4} className="font-regular" c="tm-blue">
					<Trans>Pendaftaran Online</Trans>
				</Title>
				{faskes ? (
					<Title order={2} className="font-regular text-center" c="tm-blue">
						{faskes.name}
					</Title>
				) : (
					<Skeleton width={300} height={40} />
				)}
			</div>

			{/* SESSION EXPIRED ALERT */}
			{params.get("reason") === "session-expired" && (
				<Alert mt={"sm"} variant="light" className="text-center">
					<Trans>Mohon maaf, sesi Anda telah habis. Silakan login ulang.</Trans>
				</Alert>
			)}

			<Card
				style={{ backgroundColor: "transparent" }}
				className="mt-4"
				padding={"md"}
				classNames={{ root: "w-full" }}
			>
				<form
					onSubmit={form.onSubmit(() => submitHandler())}
					className="w-full flex flex-col gap-2"
				>
					<SegmentedControl
						value={identityType}
						onChange={setIdentityType}
						fullWidth
						data={[
							{ value: "nik", label: t(i18n)`Dengan NIK` },
							{ value: "mr", label: t(i18n)`Dengan No. RM` },
						]}
					/>

					<TextInput
						key={form.key("identityNumber")}
						{...form.getInputProps("identityNumber")}
						label={
							identityType === "nik" ? (
								<strong>
									<Trans>NIK</Trans> :{" "}
								</strong>
							) : (
								<strong>
									<Trans>No. RM</Trans> :{" "}
								</strong>
							)
						}
						placeholder={
							identityType === "nik"
								? t(i18n)`Masukkan NIK`
								: t(i18n)`Masukkan No. RM`
						}
					/>
					<DateInput
						key={form.key("dob")}
						{...form.getInputProps("dob")}
						label={
							<strong>
								<Trans>Tanggal Lahir</Trans> :
							</strong>
						}
						valueFormat="DD/MM/YYYY"
						className="mb-4"
						placeholder="Format: DD/MM/YYYY"
						dateParser={(value) => dateParser(value)}
						onBlur={(event) => handleDateValidation(event.target.value)}
					/>

					<Button loading={isPending} type="submit" color="tm-blue">
						<Trans>Masuk</Trans>
					</Button>
					<Button loading={isPending} color="tm-green" onClick={open}>
						<Trans>Daftar Pasien Baru</Trans>
					</Button>
				</form>
			</Card>

			<NewPatientModal opened={opened} close={close} />
		</div>
	);
}
