import { Button, Modal, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { SaveIcon } from "lucide-react";
import { zodResolver } from "mantine-form-zod-resolver";
import { z } from "zod";
import type { Patient } from "~/types";
import { apiFetcher, dateParser } from "~/utils/utils.client";

type Props = {
	opened: boolean;
	close: () => void;
};

export const registerSchema = z.object({
	nik: z
		.string()
		.min(10, "NIK tidak valid")
		.regex(/^\d+$/, "NIK harus berupa angka"),
	salutation: z.string().min(1, "Wajib diisi"),
	fullName: z.string().min(1, "Wajib diisi"),
	dob: z.date(),
	phone: z.string().min(4, "Wajib diisi"),
	bloodType: z.string().min(1, "Wajib diisi"),
	sex: z.string().min(1, "Wajib diisi"),
	email: z.string().min(3, "Wajib diisi").email("Email tidak valid"),
	image: z.instanceof(File).nullable(),
});

type RegisterForm = z.infer<typeof registerSchema>;

export default function NewPatientModal({ opened, close }: Props) {
	const form = useForm<RegisterForm>({
		initialValues: {
			nik: "",
			salutation: "",
			fullName: "",
			dob: new Date("2000-01-01"),
			phone: "",
			bloodType: "",
			sex: "",
			email: "",
			image: null,
		},
		validate: zodResolver(registerSchema),
	});

	const patient: Patient = {
		address: "",
		dob: dayjs(form.values.dob).format("YYYY-MM-DD"),
		id: 0,
		name: form.values.fullName,
		email: form.values.email,
		salutation: form.values.salutation,
		nik: form.values.nik,
		norm: "00000",
		image: "",
		phone: form.values.phone,
		sex: form.values.sex,
		verified: false,
	};

	const { mutate: submitHandler, isPending } = useMutation({
		mutationKey: ["patient-create"],
		mutationFn: async () => {
			return await apiFetcher({
				path: "/v1/booking-online/patient/create",
				opts: {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(patient),
				},
			});
		},
		onSuccess: (data) => {
			if (data.success === false) {
				notifications.show({
					message: data.message,
					title: "Terjadi kegagalan",
					color: "red",
					withCloseButton: true,
				});
			} else {
				notifications.show({
					message: data.message,
					title: "Berhasil",
					color: "green",
					withCloseButton: true,
				});
				close();
			}
			form.reset();
		},
		onError: (error) => {
			notifications.show({
				message: error.message,
				title: "Terjadi kegagalan",
				color: "red",
				withCloseButton: true,
			});
		},
	});

	return (
		<>
			<Modal
				opened={opened}
				onClose={close}
				size={"lg"}
				centered
				styles={{
					header: { color: "white", background: "#093c71", padding: "1rem" },
					close: { color: "white", background: "#093c71" },
				}}
				title={<span className="text-lg">Pendaftaran Pasien Baru</span>}
			>
				<form onSubmit={form.onSubmit(() => submitHandler())}>
					<div className="flex flex-col md:flex-row mb-4 gap-4 mt-4">
						<div className="w-full">
							<div className="flex flex-col gap-4">
								<TextInput
									key={form.key("nik")}
									{...form.getInputProps("nik")}
									withAsterisk
									label="NIK"
									placeholder="Masukkan NIK"
								/>
								<div className="flex flex-row gap-2">
									<div className="w-1/3">
										<Select
											key={form.key("salutation")}
											{...form.getInputProps("salutation")}
											withAsterisk
											label="Nama Lengkap"
											placeholder="Sapaan"
											data={[
												{ label: "Tuan", value: "TUAN" },
												{ label: "Nona", value: "NONA" },
												{ label: "Nyonya", value: "NYONYA" },
												{ label: "Anak", value: "ANAK" },
											]}
										/>
									</div>
									<div className="w-2/3">
										<TextInput
											key={form.key("fullName")}
											{...form.getInputProps("fullName")}
											label=" "
											placeholder="Misal: Dewi Anggraini"
										/>
									</div>
								</div>
								<DateInput
									key={form.key("dob")}
									{...form.getInputProps("dob")}
									withAsterisk
									label="Tanggal Lahir"
									valueFormat="DD/MM/YYYY"
									placeholder="Format: DD/MM/YYYY"
									dateParser={(value) => dateParser(value)}
								/>
								<TextInput
									key={form.key("phone")}
									{...form.getInputProps("phone")}
									withAsterisk
									type="number"
									label="No. Telepon / HP"
								/>
								<div className="flex flex-row gap-2">
									<Select
										key={form.key("bloodType")}
										{...form.getInputProps("bloodType")}
										className="w-1/2"
										withAsterisk
										label="Golongan darah"
										placeholder="Pilih"
										data={["A", "B", "AB", "O"]}
									/>
									<Select
										key={form.key("sex")}
										{...form.getInputProps("sex")}
										className="w-1/2"
										withAsterisk
										label="Jenis Kelamin"
										placeholder="Pilih"
										data={[
											{ label: "Laki-laki", value: "L" },
											{ label: "Perempuan", value: "P" },
										]}
									/>
								</div>
								<TextInput
									key={form.key("email")}
									{...form.getInputProps("email")}
									withAsterisk
									label="Email"
									type="email"
								/>
							</div>
						</div>
					</div>
					<Button type="submit" loading={isPending} fullWidth color="tm-blue">
						<SaveIcon width={16} className="mr-2" /> Simpan
					</Button>
				</form>
			</Modal>
		</>
	);
}
